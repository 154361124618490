(function($){
	if ($(".owl-fullsize").length) {
		$('.owl-fullsize').owlCarousel({
		  items: 1,
		  dots: true,
		  video: true,
		  nav: false,
		  autoplay: true,
		  loop: true,
		  smartSpeed: 1000,
		  autoplayTimeout: 8300,
		  });
		}
		if ($(".owl-fullsize-center").length) {
			$('.owl-fullsize-center').owlCarousel({
			  items: 1,
			  dots: true,
			  video: true,
			  nav: false,
			  autoplay: true,
			  loop: true,
			  smartSpeed: 2000,
			  autoplayTimeout: 8300,
			  });
			}
			if ($(".owl-1-item").length) {
				$('.owl-1-item').owlCarousel({
				  items: 1,
				  dots: true,
				  video: true,
				  nav: false,
				  autoplay: true,
				  loop: true,
				  smartSpeed: 1000,
				  autoplayTimeout: 8300,
				  });
				}
		if ($(".owl-4-items").length) {
			$('.owl-4-items').owlCarousel({
			  items: 1,
			  margin: 56,
			  dots: true,
			  nav: false,
			  smartSpeed: 2000,
			  autoplayTimeout: 8300,
			  navText: ["<span class='icon-slide_left text-4xl'><span class='path1'></span><span class='path2'></span><span class='path3'></span></span>","<span class='icon-slide_right text-4xl'><span class='path1'></span><span class='path2'></span><span class='path3'></span></span>"],
			  responsiveClass: true,
			  responsive: {
				  480: {
					  items: 2,
					  dots: false,
					  nav: true,
				},
				1024: {
				  items: 4,
				  dots: false,
				  nav: true,
				}
			  }
			});
		  }
		  if ($(".owl-5-items").length) {
			$('.owl-5-items').owlCarousel({
			  items: 3,
			  margin: 16,
			  dots: true,
			  nav: false,
			  smartSpeed: 2000,
			  autoplayTimeout: 8300,
			  navText: ["<span class='icon-slide_left text-4xl'><span class='path1'></span><span class='path2'></span><span class='path3'></span></span>","<span class='icon-slide_right text-4xl'><span class='path1'></span><span class='path2'></span><span class='path3'></span></span>"],
			  responsiveClass: true,
			  responsive: {
				480: {
				  items: 5,
				}
			  }
			});
		  }
		  if ($(".owl-7-items").length) {
			$('.owl-7-items').owlCarousel({
			  items: 3,
			  margin: 16,
			  dots: true,
			  nav: false,
			  navText: ["<span class='icon-slide_left text-4xl'><span class='path1'></span><span class='path2'></span><span class='path3'></span></span>","<span class='icon-slide_right text-4xl'><span class='path1'></span><span class='path2'></span><span class='path3'></span></span>"],
			  responsiveClass: true,
			  responsive: {
				480: {
				  items: 5,
				},
				760: {
					items: 7,
				  }
			  }
			});
		  }
		  function stopVideo( element ) {
			  const iframe = element.querySelector( 'iframe');
			  const video = element.querySelector( 'video' );
			  if ( iframe ) {
				  var iframeSrc = iframe.src;
				  iframe.src = iframeSrc;
			  }
			  if ( video ) {
				  video.pause();
			  }
		  };
		  if ($(document.querySelectorAll('.modal-open-info')).length) {
			var openmodal = document.querySelectorAll('.modal-open-info')
			for (var i = 0; i < openmodal.length; i++) {
				openmodal[i].addEventListener('click', function(event) {
					event.preventDefault()
					var content = $(this).data('modal-content');
					if(content) {
						const modaltext = document.querySelector('.modal-content-text');
		
						modaltext.innerHTML = content;

					}
					toggleModal()
				})
			}
	
			const overlay = document.querySelector('.modal-overlay')
			overlay.addEventListener('click', toggleModal)
	
			var closemodal = document.querySelectorAll('.modal-close')
			for (var i = 0; i < closemodal.length; i++) {
				closemodal[i].addEventListener('click', toggleModal)
			}
	
			document.onkeydown = function(evt) {
				evt = evt || window.event
				var isEscape = false
				if ("key" in evt) {
					isEscape = (evt.key === "Escape" || evt.key === "Esc")
				} else {
					isEscape = (evt.keyCode === 27)
				}
				if (isEscape && document.body.classList.contains('modal-active')) {
					toggleModal()
				}
			};
	
	
			function toggleModal() {
				const body = document.querySelector('body')
				const modal = document.querySelector('.modal-info')
				modal.classList.toggle('opacity-0')
				modal.classList.toggle('pointer-events-none')
				body.classList.toggle('modal-active')
				stopVideo(document.querySelector('.modal-content-text'));	
			}

	
		}

		if ($(document.querySelectorAll('.modal-open-testimonial')).length) {
			var openmodal = document.querySelectorAll('.modal-open-testimonial')
			for (var i = 0; i < openmodal.length; i++) {
				openmodal[i].addEventListener('click', function(event) {
					event.preventDefault()
					toggleModalTestimonial()
				})
			}
	
			const overlayTestimonial = document.querySelector('.modal-overlay-testimonial')
			overlayTestimonial.addEventListener('click', toggleModalTestimonial)
	
			var closemodal = document.querySelectorAll('.modal-close-testimonial')
			for (var i = 0; i < closemodal.length; i++) {
				closemodal[i].addEventListener('click', toggleModalTestimonial)
			}
	
			document.onkeydown = function(evt) {
				evt = evt || window.event
				var isEscape = false
				if ("key" in evt) {
					isEscape = (evt.key === "Escape" || evt.key === "Esc")
				} else {
					isEscape = (evt.keyCode === 27)
				}
				if (isEscape && document.body.classList.contains('modal-active')) {
					toggleModalTestimonial()
				}
			};
	
	
			function toggleModalTestimonial() {
				const body = document.querySelector('body')
				const modaltestimonila = document.querySelector('.modal-testimonial')
				modaltestimonila.classList.toggle('opacity-0')
				modaltestimonila.classList.toggle('pointer-events-none')
				body.classList.toggle('modal-active')
				stopVideo(document.querySelector('.modal-content-text'));	
			}

	
		}

		if ($(document.querySelectorAll('.modal-open-video')).length) {
			var openmodal = document.querySelectorAll('.modal-open-video')
			for (var i = 0; i < openmodal.length; i++) {
				openmodal[i].addEventListener('click', function(event) {
					event.preventDefault()
					var content = $(this).data('modal-content');
					if(content) {
						const modaltext = document.querySelector('.modal-content-text');
		
						modaltext.innerHTML = content;

					}
					toggleModalVideo()
				})
			}
	
			const overlayVideo = document.querySelector('.modal-overlay-video')
			overlayVideo.addEventListener('click', toggleModalVideo)
	
			var closemodal = document.querySelectorAll('.modal-close-video')
			for (var i = 0; i < closemodal.length; i++) {
				closemodal[i].addEventListener('click', toggleModalVideo)
			}
	
			document.onkeydown = function(evt) {
				evt = evt || window.event
				var isEscape = false
				if ("key" in evt) {
					isEscape = (evt.key === "Escape" || evt.key === "Esc")
				} else {
					isEscape = (evt.keyCode === 27)
				}
				if (isEscape && document.body.classList.contains('modal-active')) {
					toggleModalVideo()
				}
			};
	
	
			function toggleModalVideo() {
				const body = document.querySelector('body')
				const modalvideo = document.querySelector('.modal-video')
				modalvideo.classList.toggle('opacity-0')
				modalvideo.classList.toggle('pointer-events-none')
				body.classList.toggle('modal-active')
				stopVideo(document.querySelector('.modal-content-text'));	
			}

	
		}
		
	// Iterate over each select element
	$('select').each(function () {

		// Cache the number of options
		var $this = $(this),
			numberOfOptions = $(this).children('option').length;

		// Hides the select element
		$this.addClass('s-hidden');

		// Wrap the select element in a div
		$this.wrap('<div class="select"></div>');

		// Insert a styled div to sit over the top of the hidden select element
		$this.after('<div class="styledSelect"></div>');

		// Cache the styled div
		var $styledSelect = $this.next('div.styledSelect');

		// Show the first select option in the styled div
		$styledSelect.text($this.children('option').eq(0).text());

		// Insert an unordered list after the styled div and also cache the list
		var $list = $('<ul />', {
			'class': 'options'
		}).insertAfter($styledSelect);

		// Insert a list item into the unordered list for each select option
		for (var i = 0; i < numberOfOptions; i++) {
			$('<li />', {
				text: $this.children('option').eq(i).text(),
				rel: $this.children('option').eq(i).val()
			}).appendTo($list);
		}

		// Cache the list items
		var $listItems = $list.children('li');

		// Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
		$styledSelect.click(function (e) {
			e.stopPropagation();
			if($(this).hasClass('active')) {
			$(this).removeClass('active').next('ul.options').slideUp();
			} else {
			$('div.styledSelect.active').each(function () {
				$(this).removeClass('active').next('ul.options').slideUp();
			});
			$(this).toggleClass('active').next('ul.options').slideToggle();
			}
		});

		// Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
		// Updates the select element to have the value of the equivalent option
		$listItems.click(function (e) {
			e.stopPropagation();
			$styledSelect.text($(this).text()).removeClass('active');
			$this.val($(this).attr('rel'));
			$list.slideUp();
			SubmitForm($this.data('form-id'));
			/* alert($this.val()); Uncomment this for demonstration! */
		});

		// slideUps the unordered list when clicking outside of it
		$(document).click(function () {
			$styledSelect.removeClass('active');
			$list.slideUp();
		});


		function SubmitForm(formId) {
			var oForm = document.getElementById(formId);
			if (oForm) {
				oForm.submit(); 
			}
			else {
				console.log("DEBUG - could not find element " + formId);
			}
		}
	});

	if($('.btn_initFancybox').length) {
		$('.btn_initFancybox').on('click', function(){
			var gallery = $(this).attr('data-initFancybox');
			var items = $('[data-fancybox="' + gallery + '"]');
			$.fancybox.open( items, {
				touch: false,
				caption : function(instance,item) {
					return $(this).closest('figure').find('figcaption').html();
				},
				
			});
		})
	}
	if($('.btn_accordion').length) {
		$('.btn_accordion').on('click', function(){
			var child = $(this).attr('data-service');
			console.log(child);
			$(this).toggleClass('active');
			$(child).toggleClass('active');
		});
	}
	if($('.anchor').length) {

		function scrollToAnchor(aid){
			var aTag = $(aid);
			$('html,body').animate({scrollTop: aTag.offset().top},'slow');
		}
		
		$(".anchor").click(function(e) {
			e.preventDefault();
			var aid = $(this).attr('href');
		scrollToAnchor(aid);
		})	;
	}
	$('.menu-item_parent').on('click', function() {
		$(this).toggleClass('active');
	});
})(jQuery);

